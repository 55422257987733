import debounce from 'debounce-promise';
import http from '../plugins/http';

const list = (page, per_page, search) => http.get('/item', { params: { page, per_page, ...search } });

export default {
  list,
  listDebounce: debounce(list, 400),
  get: debounce((customerId, n = 99) => http.get('/item', {
    params: {
      customer_id: customerId, per_page: n, page: 1
    }
  }), 400),
  detail: id => http.get(`/item/${id}`),
  update: (id, payload) => http.put(`/item/${id}`, payload)
};
