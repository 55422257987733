export const errorsToList = (errors) => {
  let result = '<ul>';
  errors.forEach((error) => {
    result += `<li>${error.msg}</li>`;
  });
  result += '</ul>';
  return result;
};

export const objectToList = (object) => {
  let result = '<ul>';
  Object.keys(object).forEach((key) => {
    result += `<li>${object[key]}</li>`;
  });
  result += '</ul>';
  return result;
};

export const isEmpty = (data) => {
  if (data instanceof Array) {
    return data.length === 0;
  }
  return Object.keys(data).length === 0;
};

export const flushObjectKeyValues = (object) => {
  Object.keys(object).forEach((key) => {
    object[key] = null;
  });
  return object;
};

export const shorten = (num) => {
  if (num >= 1000000000) {
    return `${(num / 1000000000).toFixed(1).replace(/\.0$/, '')}G`;
  }
  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(1).replace(/\.0$/, '')}M`;
  }
  if (num >= 1000) {
    return `${(num / 1000).toFixed(1).replace(/\.0$/, '')}K`;
  }
  return num;
};

export default {};
