import debounce from 'debounce-promise';
import http from '../plugins/http';

const list = (page, per_page, invNo) => http.get('/invoice', { params: { page, per_page, invoice_no: invNo } });

const listTicket = ({
  page = 1, per_page = 1000, invNo, status, sort
}) => http.get('/invoice/ticket', {
  params: {
    page, per_page, invoice_no: invNo, status, sort
  }
});

const listLog = ({
  page = 1, per_page = 10, prev_status = '', next_status = '', ticket_id = ''
}) => http.get('/invoice/ticket/logs', {
  params: {
    page, per_page, prev_status, next_status, ticket_id
  }
});

export default {
  list,
  listTicket,
  detail: id => http.get(`/invoice/${id}`),
  listDebounce: debounce(list, 400),
  listTicketDebounce: debounce(listTicket, 400),
  create: data => http.post('/invoice', data),
  check: debounce(invoiceNo => http.post(`/invoice/check/${invoiceNo}`), 400),
  updateTicket: (id, payload) => http.put(`/invoice/ticket/${id}`, payload),
  countTicket: () => http.get('/invoice/ticket/count'),
  listLog
};
