<template>
  <div>
    <vx-card>
      <form-wizard
        color="rgba(var(--vs-primary), 1)"
        :title="null"
        :subtitle="null"
        @on-complete="createInvoice"
        step-size="sm"
        shape="tab"
        error-color="#EA5455"
        finish-button-text="Create invoice"
      >
        <tab-content
          title="Invoice"
          class="mb-5"
          :before-change="checkInvoiceTab"
          icon="feather icon-user"
        >
          <!-- tab 1 content -->
          <div class="vx-row">
            <br>
            <!-- <div class="vx-col w-full mt-5">
              <label>New customer</label>
              <vs-switch
                color="success"
                v-model="customer.is_new"
                icon-pack="feather"
                :vs-icon="customer.is_new ? 'icon-check' : 'icon-x'"
              />
            </div> -->
            <div class="vx-col w-1/2 mt-5">
              <vs-input
                val-icon-success="check"
                val-icon-danger="clear"
                :success="invoiceCheck && invoice_no.length !== 0"
                :danger="!invoiceCheck"
                :danger-text="errors.first('invoice no')"
                v-validate="'required|alpha_dash|min:6|max:30'"
                label="Invoice No"
                v-model="invoice_no"
                class="w-full"
                name="invoice no"
              />
            </div>
            <div class="vx-col w-1/2 mt-5">
              <label class="vs-input--label">Invoice Date</label>
              <datepicker
                v-validate="'required'"
                v-model="invoice_date"
                wrapper-class="vs-con-input"
                input-class="vs-inputx vs-input--input normal"
                :monday-first="true"
                :highlighted="{ dates: [new Date()] }"
                name="invoice date"
              />
            </div>
            <div
              class="vx-col w-1/2 mt-5"
              v-if="customer.is_selected"
            >
              <vs-input
                val-icon-danger="clear"
                :danger="errors.has('name')"
                :danger-text="errors.first('name')"
                v-validate="'required|min:2|max:50'"
                label="Name"
                v-model="customer.name"
                class="w-full"
                name="name"
                :disabled="customer.id !== null"
              />
            </div>
            <div
              class="vx-col w-1/2 mt-5"
              v-if="customer.is_selected"
            >
              <vs-input
                val-icon-danger="clear"
                :danger="errors.has('phone')"
                :danger-text="errors.first('phone')"
                v-validate="'required|numeric|min:10|max:20'"
                label="Phone"
                v-model="customer.phone"
                class="w-full"
                name="phone"
                :disabled="customer.id !== null"
              />
            </div>
            <div
              class="vx-col w-full mt-5"
              v-if="!customer.is_selected"
            >
              <label class="vs-input--label">Customer</label>
              <multiselect
                :class="{ 'is-danger': errors.has('customer') }"
                v-validate="'required'"
                :options="fillables.customers"
                :searchable="true"
                :loading="loading.customer"
                :close-on-select="true"
                :show-labels="true"
                :custom-label="({ name }) => name"
                @search-change="loadCustomer"
                @input="loadCustomerData"
                @remove="clearItems"
                open-direction="bottom"
                placeholder="Select exsisting customer"
                name="customer"
                :taggable="true"
                tag-placeholder="New Customer"
                @tag="(val) => loadCustomerData(val)"
              >
                <div slot="noResult">
                  Customer not found
                </div>
                <div slot="noOptions">
                  Type customer name or phone
                </div>
              </multiselect>
              <div
                v-if="errors.has('customer')"
                class="con-text-validation span-text-validation-danger vs-input--text-validation-span v-enter-to"
                style="height: 19px;"
              >
                <span class="span-text-validation">
                  {{ errors.first('customer') }}
                </span>
              </div>
              <br>
            </div>
          </div>
        </tab-content>

        <tab-content
          title="Items"
          class="mb-5"
          :before-change="checkItemsTab"
          icon="feather icon-list"
        >
          <!-- tab 2 content -->
          <div class="vx-row">
            <div class="vx-col w-full">
              <vs-table
                :data="items"
                no-data-text="There are no item(s)"
              >
                <template slot="thead">
                  <vs-th>Type</vs-th>
                  <vs-th>Brand</vs-th>
                  <vs-th>Model</vs-th>
                  <vs-th>Details</vs-th>
                  <vs-th>Treatments</vs-th>
                  <vs-th />
                </template>

                <template>
                  <vs-tr
                    :key="i"
                    v-for="(tr, i) in items"
                    :state="tr.is_new ? 'success' : ''"
                  >
                    <vs-td>{{ tr.type }}</vs-td>
                    <vs-td>{{ tr.brand }}</vs-td>
                    <vs-td>{{ tr.model }}</vs-td>
                    <vs-td>{{ `${tr.material || '-'} / ${tr.color || '-'} / ${tr.size || '-'} / ${tr.lace || '-'} / ${tr.insole || '-'}` }}</vs-td>
                    <vs-td>
                      <vs-chip
                        v-for="(item, ind) in tr.treatments"
                        color="primary"
                        :key="ind"
                      >
                        {{ item.name }}
                      </vs-chip>
                    </vs-td>
                    <vs-td>
                      <div class="btn-group-vertical">
                        <vs-button size="small" color="warning" @click="() => loadItemToUpdate(i)">
                          Edit
                        </vs-button>
                        <vs-button size="small" color="danger" @click="() => removeItem(i)">
                          Delete
                        </vs-button>
                      </div>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
              <br>
              <vs-button
                @click="itemModal.is_active=true"
                color="success"
                type="filled"
                icon-pack="feather"
                icon="icon-plus"
                size="small"
              />
            </div>
          </div>
        </tab-content>

        <tab-content
          title="Review"
          class="mb-5"
          icon="feather icon-check"
        >
          <!-- INVOICE METADATA -->
          <div
            class="vx-row leading-loose"
            style="padding: 10px;"
          >
            <div class="vx-col w-full md:w-1/2 mt-12">
              <h5>CUSTOMER</h5>
              <div class="invoice__recipient-contact">
                <p class="flex items-center">
                  <feather-icon
                    icon="UserIcon"
                    svg-classes="h-4 w-4"
                  />
                  <span class="ml-2">{{ customer.name }}</span>
                </p>
                <p class="flex items-center">
                  <feather-icon
                    icon="PhoneIcon"
                    svg-classes="h-4 w-4"
                  />
                  <span class="ml-2">{{ customer.phone }}</span>
                </p>
              </div>
            </div>
            <div class="vx-col w-full md:w-1/2 mt-12 text-right">
              <div class="invoice__invoice-detail">
                <h5>INVOICE</h5>
                <p>{{ invoice_no }}</p>
                <p>{{ formatedDate }}</p>
              </div>
            </div>
          </div>

          <!-- INVOICE CONTENT -->
          <div style="padding: 10px;">
            <!-- INVOICE TASKS TABLE -->
            <vs-table
              hover-flat
              :data="items"
            >
              <!-- HEADER -->
              <template slot="thead">
                <vs-th>ITEM</vs-th>
                <vs-th>DETAILS</vs-th>
                <vs-th>TREATMENTS</vs-th>
                <!-- <vs-th>AMOUNT</vs-th> -->
              </template>

              <!-- DATA -->
              <template slot-scope="{data}">
                <vs-tr
                  v-for="(tr, index) in data"
                  :key="index"
                >
                  <vs-td>
                    {{ data[index].brand }} {{ data[index].model }} ({{ data[index].type }})
                  </vs-td>
                  <vs-td>
                    <vs-td>{{ `${data[index].material || '-'} / ${data[index].color || '-'} / ${data[index].size || '-'} / ${data[index].lace || '-'} / ${data[index].insole || '-'}` }}</vs-td>
                  </vs-td>
                  <vs-td>
                    <vs-chip
                      v-for="(item, ind) in data[index].treatments"
                      color="teal"
                      :key="ind"
                    >
                      {{ item.name }}
                    </vs-chip>
                  </vs-td>
                  <!-- <vs-td>
                    Rp. {{ data[index].treatments.reduce((acc, item) => acc + +item.cost, 0) }}
                  </vs-td> -->
                </vs-tr>
              </template>
            </vs-table>

            <!-- INVOICE SUMMARY TABLE -->
            <!-- <vs-table hover-flat class="w-1/2 ml-auto mt-4" :data="invoice_data">
              <vs-tr>
                <vs-th>SUBTOTAL</vs-th>
                <vs-td>Rp. {{ invoice_data.subtotal }}</vs-td>
              </vs-tr>
              <vs-tr>
                <vs-th>DISCOUNT ({{ invoice_data.discount_percentage }}%)</vs-th>
                <vs-td>Rp. {{ invoice_data.discounted_amount }}</vs-td>
              </vs-tr>
              <vs-tr>
                <th>TOTAL</th>
                <td>Rp. {{ invoice_data.total }}</td>
              </vs-tr>
            </vs-table> -->
          </div>
        </tab-content>
      </form-wizard>
    </vx-card>
    <vs-popup
      class="holamundo"
      title="Add item to invoice"
      :active.sync="itemModal.is_active"
    >
      <div
        class="vx-row"
        data-vv-scope="item-modal"
      >
        <div
          class="vx-col w-full mt-5"
          v-show="!isNewItemEligible"
        >
          <label class="vs-input--label">Item</label>
          <multiselect
            :class="{ 'is-danger': errors.has('item') }"
            v-validate="'required'"
            name="item"
            :key="'item'"
            :options="fillables.items"
            :searchable="true"
            :loading="loading.item"
            :close-on-select="true"
            :show-labels="true"
            :custom-label="(item) => item.brand ? itemSelectTransformer(item) : '-- Add New Item --'"
            open-direction="bottom"
            placeholder="Select item"
            :max-height="200"
            @input="changeItem"
          >
            <div slot="noResult">
              Item not found
            </div>
          </multiselect>
          <div
            v-if="errors.has('item')"
            class="con-text-validation span-text-validation-danger vs-input--text-validation-span v-enter-to"
            style="height: 19px;"
          >
            <span class="span-text-validation">
              {{ errors.first('item') }}
            </span>
          </div>
        </div>
        <div
          v-show="isNewItemEligible"
          class="vx-col w-full mt-5"
        >
          <label class="vs-input--label">Type</label>
          <multiselect
            :class="{ 'is-danger': errors.has('type') }"
            v-validate="'required'"
            name="type"
            placeholder="Select type"
            label="name"
            track-by="name"
            :custom-label="({ name }) => capitalize(name)"
            :searchable="true"
            v-model="itemModal.form.type"
            :options="fillables.types"
          >
            <div slot="noResult">
              Type not found
            </div>
          </multiselect>
          <div
            v-if="errors.has('type')"
            class="con-text-validation span-text-validation-danger vs-input--text-validation-span v-enter-to"
            style="height: 19px;"
          >
            <span class="span-text-validation">
              {{ errors.first('type') }}
            </span>
          </div>
        </div>
        <div
          v-show="isNewItemEligible"
          class="vx-col w-1/2 mt-5"
        >
          <label class="vs-input--label">Brand</label>
          <multiselect
            @search-change="(val) => loadFillables('brand', val, 'brands')"
            placeholder="Select brand"
            label="name"
            track-by="name"
            :custom-label="({ name }) => name"
            :searchable="true"
            v-validate="'required'"
            name="brand"
            v-model="itemModal.form.brand"
            :options="fillables.brands"
            :taggable="true"
            :max-height="200"
            :class="{ 'is-danger': errors.has('brand') }"
            @tag="(val) => addTag(val, 'brand')"
            :loading="loading.brand"
            tag-placeholder="Add new brand"
          >
            <div slot="noOptions">
              Type brand name
            </div>
          </multiselect>
          <div
            v-if="errors.has('brand')"
            class="con-text-validation span-text-validation-danger vs-input--text-validation-span v-enter-to"
            style="height: 19px;"
          >
            <span class="span-text-validation">
              {{ errors.first('brand') }}
            </span>
          </div>
        </div>
        <div
          v-show="isNewItemEligible"
          class="vx-col w-1/2 mt-5"
        >
          <label class="vs-input--label">Model</label>
          <multiselect
            @search-change="(val) => loadFillables('model', val, 'models')"
            placeholder="Select model"
            label="name"
            track-by="name"
            :custom-label="({ name }) => name"
            :searchable="true"
            v-validate="'required'"
            name="model"
            v-model="itemModal.form.model"
            :options="fillables.models"
            :taggable="true"
            :max-height="200"
            :class="{ 'is-danger': errors.has('model') }"
            @tag="(val) => addTag(val, 'model')"
            :loading="loading.model"
            tag-placeholder="Add new model"
          >
            <div slot="noOptions">
              Type model name
            </div>
          </multiselect>
          <div
            v-if="errors.has('model')"
            class="con-text-validation span-text-validation-danger vs-input--text-validation-span v-enter-to"
            style="height: 19px;"
          >
            <span class="span-text-validation">
              {{ errors.first('model') }}
            </span>
          </div>
        </div>
        <div
          v-show="isNewItemEligible"
          class="vx-col w-1/2 mt-5"
        >
          <label class="vs-input--label">Material</label>
          <multiselect
            @search-change="(val) => loadFillables('material', val, 'materials')"
            placeholder="Select material"
            label="name"
            track-by="name"
            :custom-label="({ name }) => name"
            :searchable="true"
            v-model="itemModal.form.material"
            :options="fillables.materials"
            :taggable="true"
            :max-height="200"
            @tag="(val) => addTag(val, 'material')"
            :loading="loading.material"
            tag-placeholder="Add new material"
          >
            <div slot="noOptions">
              Type material name
            </div>
          </multiselect>
        </div>
        <div
          v-show="isNewItemEligible"
          class="vx-col w-1/2 mt-5"
        >
          <label class="vs-input--label">Color</label>
          <multiselect
            @search-change="(val) => loadFillables('color', val, 'colors')"
            placeholder="Select color"
            label="name"
            track-by="name"
            :custom-label="({ name }) => name"
            :searchable="true"
            v-model="itemModal.form.color"
            :options="fillables.colors"
            :taggable="true"
            :max-height="200"
            @tag="(val) => addTag(val, 'color')"
            :loading="loading.color"
            tag-placeholder="Add new color"
          >
            <div slot="noOptions">
              Type color name
            </div>
          </multiselect>
        </div>
        <div
          v-show="isNewItemEligible"
          class="vx-col w-1/2 mt-5"
        >
          <label class="vs-input--label">Lace</label>
          <multiselect
            @search-change="(val) => loadFillables('lace', val, 'laces')"
            placeholder="Select lace"
            label="name"
            track-by="name"
            :custom-label="({ name }) => name"
            :searchable="true"
            v-model="itemModal.form.lace"
            :options="fillables.laces"
            :taggable="true"
            :max-height="200"
            @tag="(val) => addTag(val, 'lace')"
            :loading="loading.lace"
            tag-placeholder="Add new lace"
          >
            <div slot="noOptions">
              Type lace name
            </div>
          </multiselect>
        </div>
        <div
          v-show="isNewItemEligible"
          class="vx-col w-1/2 mt-5"
        >
          <vs-input
            val-icon-danger="clear"
            name="insole"
            :danger="errors.has('insole')"
            :danger-text="errors.first('insole')"
            v-validate="'min:1|max:30'"
            size="normal"
            label="Insole"
            v-model="itemModal.form.insole"
            class="w-full"
          />
        </div>
        <div
          v-show="isNewItemEligible"
          class="vx-col w-1/2 mt-5"
        >
          <vs-input
            val-icon-danger="clear"
            name="size"
            :danger="errors.has('size')"
            :danger-text="errors.first('size')"
            v-validate="'min:1|max:15'"
            size="normal"
            label="Size"
            v-model="itemModal.form.size"
            class="w-full"
          />
        </div>
        <div
          class="vx-col w-1/2 mt-5" v-show="itemModal.item_selected"
        >
          <vs-input
            val-icon-danger="clear"
            name="notes"
            :danger="errors.has('notes')"
            :danger-text="errors.first('notes')"
            v-validate="'max:100'"
            size="normal"
            label="Notes"
            v-model="itemModal.form.notes"
            class="w-full"
          />
        </div>
        <div class="vx-col w-full mt-5" v-show="itemModal.item_selected">
          <label class="vs-input--label">Treatments</label>
          <multiselect
            :class="{ 'is-danger': errors.has('treatments') }"
            v-validate="'required'"
            name="treatments"
            :multiple="true"
            :searchable="true"
            v-model="itemModal.form.treatments"
            :options="fillables.treatments"
            :custom-label="({ name }) => name"
            open-direction="top"
            label="name"
            track-by="name"
            :max-height="175"
            placeholder="Select treatments"
          />
          <div
            v-if="errors.has('treatments')"
            class="con-text-validation span-text-validation-danger vs-input--text-validation-span v-enter-to"
            style="height: 19px;"
          >
            <span class="span-text-validation">
              {{ errors.first('treatments') }}
            </span>
          </div>
        </div>
        <vs-divider />
        <div class="vx-col w-full mt-5">
          <div class="flex justify-between">
            <vs-button
              @click="clearFormData"
              color="warning"
              type="filled"
            >
              Reset
            </vs-button>
            <vs-button
              @click="addItem"
              color="success"
              type="filled"
            >
              Add Item
            </vs-button>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import { FormWizard, TabContent } from 'vue-form-wizard';
import moment from 'moment';
import CustomerRepo from '@/repositories/customer_repo';
import TreatmentRepo from '@/repositories/treatment_repo';
import FillableRepo from '@/repositories/fillable_repo';
import InvoiceRepo from '@/repositories/invoice_repo';
import ItemRepo from '@/repositories/item_repo';
import { isEmpty } from '@/utils/helpers';

export default {
  data () {
    return {
      invoice_no: '',
      invoice_date: moment().format('YYYY-MM-DD'),
      customer: {
        is_selected: false,
        id: null,
        name: '',
        phone: ''
      },
      items: [],
      itemModal: {
        is_active: false,
        item_selected: false,
        form: {
          is_new: false,
          item_id: null,
          type: null,
          brand: null,
          model: null,
          material: null,
          size: null,
          color: null,
          lace: null,
          treatments: [],
          notes: null
        }
      },
      fillables: {
        items: [{
          item_id: null, brand: null, model: null, color: null, lace: null, material: null, type: null, size: null
        }],
        customers: [],
        treatments: [],
        types: [{ name: 'Shoes' }, { name: 'Bag' }, { name: 'Accessories' }],
        brands: [],
        models: [],
        materials: [],
        colors: [],
        laces: []
      },
      loading: {
        customer: false,
        brand: false,
        model: false,
        material: false,
        color: false,
        lace: false,
        item: false
      },
      invoice_data: {
        subtotal: 0,
        discount_percentage: 0,
        discounted_amount: 0,
        total: 0
      }
    };
  },
  computed: {
    isNewItemEligible () {
      return this.itemModal.item_selected === true;
    },
    formatedDate () {
      return moment(this.invoice_date).format('DD-MM-YYYY');
    },
    invoiceCheck () {
      return !this.errors.has('invoice no');
    },
    branch() {
      return this.$store.getters.getBranch;
    }
  },
  methods: {
    createInvoice () {
      const transformTreatments = item => item.id;
      const payload = {
        invoice_no: this.invoice_no,
        invoice_date: this.invoice_date,
        customer: !this.customer.id ? { is_new: true, name: this.customer.name, phone: this.customer.phone } : { is_new: false, customer_id: this.customer.id },
        items: this.items.map(item => ({ ...item, treatments: item.treatments.map(transformTreatments) }))
      };

      return InvoiceRepo.create(payload)
        .then(() => {
          this.$vs.notify({
            title: 'Success',
            text: 'New invoice created',
            color: 'success',
            position: 'bottom-center'
          });
          setTimeout(() => {
            this.$router.push(`/invoices/${this.invoice_no}`);
          }, 1000);
        })
        .catch((err) => {
          this.$vs.notify({
            title: 'Whoops',
            text: err.message,
            color: 'danger',
            position: 'bottom-center'
          });
        });
    },
    loadCustomer (name) {
      if (name.length !== 0) {
        this.loading.customer = true;
        return CustomerRepo.listDebounce(1, 999, name)
          .then(({ data: { data: customers } }) => {
            const mapped = customers.map(item => ({ name: `${item.name} / ${item.phone}`, value: item.id }));
            this.fillables.customers = mapped;
            this.loading.customer = false;
          });
      }
      this.customers = [];
    },
    loadTreatments () {
      return TreatmentRepo.list(30)
        .then(({ data: { data: treatments } }) => {
          this.fillables.treatments = treatments;
        });
    },
    addTag (newTag, field) {
      const collections = `${field}s`;
      const tag = { name: newTag };
      this.fillables[collections].push(tag);
      this.itemModal.form[field] = tag;
    },
    loadFillables (type, value) {
      const collection = `${type}s`;
      if (value.length !== 0) {
        this.loading[type] = true;
        return FillableRepo.get(type, value, collection)
          .then(({ data: { data: fillables } }) => {
            this.fillables[collection] = fillables.map(item => ({ name: this.capitalize(item.value) }));
            this.loading[type] = false;
          });
      }
      this.fillables[collection] = [];
    },
    loadCustomerData (input) {
      if (typeof input === 'object') {
        const [name, phone] = input.name.split(' / ');
        this.customer.is_selected = true;
        this.customer.name = name;
        this.customer.phone = phone;
        this.customer.id = input.value;
      } else if (typeof input === 'string') {
        this.customer.is_selected = true;
        this.customer.id = null;
        if (input.match(/^[0-9]+$/)) { this.customer.phone = input; }
        else { this.customer.name = input; }
      }
    },
    async populateCustomerItem(id) {
      this.clearItems();
      return ItemRepo.get(id)
        .then(({ data: { data: items } }) => {
          items.forEach((item) => {
            this.fillables.items.push({
              item_id: item.id,
              brand: item.brand,
              model: item.model,
              color: item.color,
              lace: item.lace,
              material: item.material,
              type: item.type,
              size: item.size,
              insole: item.insole
            });
          });
          this.loading.item = false;
        });
    },
    async addItem () {
      if (await this.checkItemAddModal()) {
        const data = this.itemModal.form;
        const index = this.items.findIndex(item => item.item_id === data.item_id);
        const payload = {
          is_new: data.is_new,
          item_id: data.item_id || uuidv4(),
          type: data.type.name,
          brand: data.brand.name,
          model: data.model.name,
          material: data.material && data.material.name,
          color: data.color && data.color.name,
          size: data.size,
          lace: data.lace && data.lace.name,
          insole: data.insole,
          treatments: data.treatments,
          notes: data.notes
        };

        if (index === -1) {
          this.items.push(payload);
        } else {
          this.items[index] = payload;
        }

        this.clearFormData();
        this.itemModal.is_active = false;
      }
    },
    async loadItemToUpdate (id) {
      this.changeItem(this.items[id]);
      this.itemModal.is_active = true;
      this.itemModal.item_selected = true;
    },
    removeItem (index) {
      this.items.splice(index, 1);
    },
    clearFormData () {
      this.itemModal.form = {
        item_id: null,
        type: null,
        brand: null,
        model: null,
        material: null,
        size: null,
        color: null,
        lace: null,
        insole: null,
        treatments: []
      };
      this.itemModal.item_selected = false;
      this.$validator.reset();
    },
    changeItem ({
      brand, model, material, size, color, lace, insole, type, item_id: itemId, treatments = [], is_new: isNew
    } = {}) {
      this.itemModal.form = {
        is_new: isNew || !itemId,
        type: { name: type },
        brand: { name: brand },
        material: { name: material },
        model: { name: model },
        color: { name: color },
        lace: { name: lace },
        insole,
        size,
        item_id: itemId || null,
        treatments
      };
      this.itemModal.item_selected = true;
    },
    clearItems () {
      this.items = [];
      this.fillables.items.splice(1, this.fillables.items.length);
    },
    async checkInvoiceTab () {
      await this.$validator.validate('invoice no');
      await this.$validator.validate('invoice date');

      await this.$validator.validate('name');
      await this.$validator.validate('phone');

      const invoiceValid = await this.checkInvoiceNo(this.invoice_no);
      return isEmpty(this.errors.items) && invoiceValid;
    },
    checkItemsTab () {
      const isEligible = isEmpty(this.errors.items) && !isEmpty(this.items);
      if (!isEligible) {
        this.$vs.notify({
          title: 'Whoops', text: 'you have to add item(s) first.', color: 'danger', position: 'bottom-center'
        });
      }
      this.recalculateInvoice();
      return isEligible;
    },
    async checkItemAddModal () {
      await this.$validator.validate('treatments');
      await this.$validator.validate('type');
      await this.$validator.validate('brand');
      await this.$validator.validate('model');
      await this.$validator.validate('size');
      return isEmpty(this.errors.items);
    },
    addErrorPool (fieldName, message) {
      const field = this.$validator.fields.find({ name: fieldName });
      field.setFlags({ invalid: true });
      this.errors.add({
        field: fieldName, msg: message, id: field.id, scope: field.scope
      });
    },
    checkInvoiceNo (invoiceNo) {
      if (!invoiceNo) {
        return false;
      }

      return InvoiceRepo.check(invoiceNo)
        .then(({ data: { is_available: available } }) => {
          if (!available) this.addErrorPool('invoice no', 'invoice already exsist');
          return true;
        });
    },
    recalculateInvoice () {
      let subtotal = 0;
      this.items.forEach((item) => {
        const ammount = item.treatments.reduce((acc, itm) => acc + +itm.cost, 0);
        subtotal += ammount;
      });
      this.invoice_data.subtotal = subtotal;
      this.invoice_data.total = subtotal + this.invoice_data.discounted_amount;
    },
    capitalize(value) {
      const splitted = value.split('');
      splitted[0] = splitted[0].toUpperCase();
      return splitted.join('');
    },
    itemSelectTransformer(item) {
      const itemDetail = `(${[item.material, item.color, item.size, item.lace, item.insole].filter(i => i && i !== '-').join(' / ')})`;
      return `${item.brand} ${item.model} ${itemDetail}`;
    }
  },
  watch: {
    async invoice_no (value) {
      this.invoice_no = value.toUpperCase();
      if (value.length >= 6) {
        return this.checkInvoiceNo(value);
      }
    },
    'customer.id': async function(value) {
      await this.populateCustomerItem(value);
    }
  },
  components: {
    FormWizard,
    TabContent
  },
  async mounted () {
    await this.loadTreatments();
  }
};
</script>

<style>
.is-danger .multiselect__tags {
  border-color: #ea5455;
}
</style>
